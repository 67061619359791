import barba from '@barba/core'
import gsap from 'gsap'

import Signals from '../cores/Signals'

import { getPathname } from '../cores/Utils.js'

const routes = require('../routes.json')

class Menu {
  constructor() {
    this.positionX = 0
    this.positionY = this.rounded = 0
    this.activeIndex = 0
    this.pause = false
    this.transition = false
    this.speed = 0
    this.minY = 0
    this.startAnimPlayed = false
    this.isOpen = true

    this.locations = Object.values(routes)

    const close = document.querySelector('.menu__close')
    close.onclick = () => this.onClose()

    this.getDOMElements()
    this.setEvents()
    this.updateLanguageChangeLinks()

    Signals.onTransition.add(() => {
      this.onClose()
    })
  }

  onOpen() {
    this.wrapper.style.display = 'flex'

    gsap.to(this.wrapper, {
      autoAlpha: 1,
      duration: 0.5,
      ease: 'power4' // .easeOut by default
    })
    gsap.to(this.img, {
      opacity: 1,
      duration: 1,
      delay: 0.5
    })
    gsap.to(this.menuLinksContainer, {
      x: 0,
      opacity: 1,
      duration: 0.5,
      delay: 0
    })
  }

  onClose() {
    gsap.to(this.wrapper, {
      autoAlpha: 0,
      duration: 0.6,
      delay: 0.2
    })
    gsap.to(this.img, {
      opacity: 0,
      duration: 0.2,
      delay: 0
    })
    gsap.to(this.menuLinksContainer, {
      x: -50,
      opacity: 0,
      duration: 0.5,
      delay: 0,
      onComplete: () => {
        this.wrapper.style.display = 'none'
      }
    })
  }

  updateLanguageChangeLinks() {
    const currentLocation = this.getCurrentLocation();

    this.changeFr.onclick = () => {
      window.location = currentLocation ? `${currentLocation.fr}` : '/'
    }
    this.changeEn.onclick = () => {
      window.location = currentLocation ? `${currentLocation.en}` : '/en/'
    }
  }

  getCurrentLocation() {
    const currentPathname = getPathname();

    const currentLocation = this.locations.filter(l => {
      return l.fr === `/${currentPathname}/` || l.en === `/en/${currentPathname}/`
    })

    return currentLocation.length ? currentLocation[0] : null
  }

  getDOMElements() {
    this.wrapper = document.querySelector('.wrapperMenu')
    this.img = document.querySelector('.menu__img')
    this.close = document.querySelector('.menu__close')
    this.menuLinksContainer = document.querySelectorAll('.menu__links')
    this.menuLinks = document.querySelectorAll('.menu__item')
    this.menuLinksTexts = document.querySelectorAll('.menu__item__text')

    this.changeFr = document.getElementById('change_lang_fr')
    this.changeEn = document.getElementById('change_lang_en')
  }

  setEvents() {
    this.menuLinksTexts.forEach(link => {
      link.onclick = event => this.goTo(event)
    })
  }

  goTo(event) {
    event.preventDefault()
    barba.go(event.target.getAttribute('href'))
  }

  update() {
    this.getDOMElements()
    this.updateLanguageChangeLinks()
  }

  animate() {
    if (!this.startAnimPlayed) {
      this.startAnimPlayed = true
      gsap.to(this.img, {
        opacity: 1,
        scale: (1, 1),
        duration: 1.5
      })
    }

    this.menuLinks.forEach((menuLink, index) => {
      
      menuLink.onmouseenter = () => {
        if (this.img.src.match(new RegExp(`\/images\/menu-${index + 1}\.png`))) return
        
        gsap.to(this.img, {
          opacity: 0,
          duration: 0.3
        })
        gsap.to(this.img, {
          opacity: 1,
          duration: 1.5,
          delay: 0.3,
          ease: 'Ease.Power2.easeIn'
        })
        setTimeout(() => {
          this.img.src = `/images/menu/m${index + 1}.jpg`

          // Fix
          this.img.style = index + 1 == 3 ? 'object-position: center 65%' : 'object-position: top';

        }, 300)
      }
    })
  }

  destroy() {}
}

export default Menu
