import gsap from "gsap"
import { isMobile } from "./Utils"

class HistoryImagesHandler {
  constructor() {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.001,
    }

    this.observer = new IntersectionObserver((observer) => {
      if (observer[0].isIntersecting) {
        const imgs = document.querySelectorAll(".next_img")
        const texts = document.querySelectorAll(".next_text")
        imgs.forEach((img, index) => {
          gsap.to(img, {
            x: isMobile() ? (index % 2 === 0 ? 100 : -100) : 0,
            y: isMobile() ? 0 : index % 2 === 0 ? 100 : -100,
            scale: 0.95,
            duration: 2,
          })

          texts.forEach((text, index) => {
            gsap.to(text, {
              x: 0,
              y: isMobile() || index % 2 !== 0 ? 20 : -20,
              opacity: 1,
              duration: 1.5,
              delay: 0.5,
            })
          })
        })
      }
    }, observerOptions)
  }

  animate() {
    this.observer.observe(document.querySelector(".next_images"))
  }
}

export default HistoryImagesHandler
