import barba from "@barba/core";
// Router just usefull to play animation by name.
// See here : https://github.com/barbajs/barba/issues/433
// import barbaRouter from '@barba/router'
import Home from "../views/Home";
import Element from "../views/Element";
import SeaHistory from "../views/SeaHistory";
import gsap from "gsap";
import Common from "../views/Common";
import WineHistory from "../views/WineHistory";
import EarthHistory from "../views/EarthHistory";
import WhiteWine from "../views/WhiteWine";
import RoseWine from "../views/RoseWine";
import EspacePro from "../views/EspacePro";
import MentionsLegales from "../views/MentionsLegales";
import Error from "../views/Error";

import MenuBar from "../cores/MenuBar";
import Menu from "../views/Menu";
import Signals from "../cores/Signals";

import App from "../app";
import Confirmation from "../views/Confirmation";

class BarbaRouter {
  constructor() {
    this.fired = false;
    this.namespace = null;
    this.menuBar = new MenuBar();
    this.menu = new Menu();
    this.init();
  }

  init() {
    const setNavbarHome = () => {
      this.menuBar.alwaysOpen = true;
      this.menuBar.navbar.classList.remove(
        "hamburger_navbar",
        "classic_navbar",
      );
      this.menuBar.navbar.classList.add("home_navbar");
      this.menuBar.update();
      this.menuBar.animate(1);
      this.menu.animate();
    };
    const setNavbarClassic = () => {
      this.menuBar.alwaysOpen = false;
      this.menuBar.navbar.classList.remove("hamburger_navbar", "home_navbar");
      this.menuBar.navbar.classList.add("classic_navbar");
      this.menuBar.update();
      this.menuBar.animate();
      this.menu.animate();
    };
    const setNavbarHamburger = () => {
      this.menuBar.alwaysOpen = true;
      this.menuBar.navbar.classList.remove("home_navbar", "classic_navbar");
      this.menuBar.navbar.classList.add("hamburger_navbar");
      this.menuBar.update();
      this.menuBar.animate();
      this.menu.animate();
    };

    barba.hooks.beforeEnter((data) => {
      // We reset scrolls values

      window.speed = 0;
      window.speedX = 0;
      window.speedY = 0;

      // We check if an anchor is targeted
      if (data.next.url.hash && data.next.url.hash.length > 0) {
        App.paused();
      }

      this.namespace = data.next.namespace;

      this.updateView(data.next.namespace);

      // We have no REFERER
      if (!data.current.container) {
        App.update(this.view);
      }
    });

    barba.hooks.after((data) => {
      App.update(this.view);
    });

    barba.init({
      views: [
        {
          namespace: "home",
          beforeEnter() {
            setNavbarHome();
            if (window.States.soundEnabled) {
              Signals.soundChange.dispatch(true);
            }
          },
        },
        {
          namespace: "element",
          beforeEnter() {
            setNavbarHamburger();
            if (window.States.soundEnabled) {
              Signals.soundChange.dispatch(true);
            }
          },
        },
        {
          namespace: "seaHistory",
          beforeEnter() {
            setNavbarClassic();
            Signals.soundChange.dispatch(false);
          },
        },
        {
          namespace: "earthHistory",
          beforeEnter() {
            setNavbarClassic();
            Signals.soundChange.dispatch(false);
          },
        },
        {
          namespace: "wineHistory",
          beforeEnter() {
            setNavbarClassic();
            Signals.soundChange.dispatch(false);
          },
        },
        {
          namespace: "common",
          beforeEnter: (data) => {
            setNavbarClassic();
            Signals.soundChange.dispatch(false);
          },
          afterEnter: (data) => {
            if (data.next.url.hash && data.next.url.hash.length > 0) {
              this.view.scrollTo(data.next.url.hash);
            }
          },
        },
        {
          namespace: "whiteWine",
          beforeEnter() {
            setNavbarClassic();
            Signals.soundChange.dispatch(false);
          },
        },
        {
          namespace: "roseWine",
          beforeEnter() {
            setNavbarClassic();
            Signals.soundChange.dispatch(false);
          },
        },
        {
          namespace: "espacePro",
          afterEnter() {
            setNavbarClassic();
            Signals.soundChange.dispatch(false);
          },
        },
        {
          namespace: "mentionsLegales",
          afterEnter() {
            setNavbarClassic();
            Signals.soundChange.dispatch(false);
          },
        },
        {
          namespace: "confirmation",
          afterEnter() {
            setNavbarClassic();
            Signals.soundChange.dispatch(false);
          },
        },
        {
          namespace: "error",
          afterEnter() {
            setNavbarClassic();
            Signals.soundChange.dispatch(false);
          },
        },
      ],
      transitions: [
        {
          once(data) {
            Signals.onRouted.dispatch();
          },
        },
        {
          name: "transition-to-element",
          from: { namespace: ["home"] },
          to: { namespace: ["element"] },
          sync: true,
          leave(data) {
            return gsap.to(data.current.container, {
              yPercent: -100,
              opacity: 0,
              duration: 1,
              ease: "Power2.easeOut",
            });
          },
          enter(data) {
            return gsap.from(data.next.container, {
              opacity: 0,
              duration: 0.4,
            });
          },
          after: () => {},
        },
        {
          name: "transition-to-home2",
          from: { namespace: ["common"] },
          to: { namespace: ["home"] },
          leave(data) {
            return gsap.to(data.current.container, {
              opacity: 0,
              duration: 2,
            });
          },
          enter(data) {
            return gsap.from(data.next.container, {
              opacity: 0,
            });
          },
          after: () => {},
        },
        {
          name: "transition-to-home",
          from: { namespace: ["element"] },
          to: { namespace: ["home"] },
          leave(data) {
            return gsap.to(data.current.container, {
              opacity: 0,
              duration: 2,
            });
          },
          enter(data) {
            return gsap.from(data.next.container, {
              opacity: 0,
            });
          },
          after: () => {},
        },
        {
          name: "transition-to-common",
          from: { namespace: ["element"] },
          to: { namespace: ["common"] },
          sync: true,
          leave(data) {
            return gsap.to(data.current.container, {
              opacity: 0,
              xPercent: -100,
              duration: 1,
            });
          },
          enter(data) {
            return gsap.from(data.next.container, {
              opacity: 0,
              xPercent: 100,
              duration: 1,
            });
          },
          after: () => {},
        },
        {
          name: "transition-to-wine",
          from: { namespace: ["common", "whiteWine", "roseWine"] },
          to: { namespace: ["whiteWine", "roseWine", "common"] },
          sync: true,
          leave(data) {
            return gsap.to(data.current.container, {
              opacity: 0,
              duration: 1,
            });
          },
          enter(data) {
            return gsap.from(data.next.container, {
              opacity: 0,
              delay: 1,
              duration: 1,
            });
          },
          after: () => {},
        },
        {
          name: "transition-to-history",
          from: {
            namespace: ["common", "seaHistory", "earthHistory", "wineHistory"],
          },
          to: {
            namespace: ["seaHistory", "earthHistory", "wineHistory", "common"],
          },
          sync: true,
          leave(data) {
            return gsap.to(data.current.container, {
              opacity: 0,
              duration: 1,
            });
          },
          enter(data) {
            return gsap.from(data.next.container, {
              opacity: 0,
              delay: 1,
              duration: 1,
            });
          },
          after: () => {},
        },
        {
          name: "transition-to-espacePro",
          from: {
            namespace: [
              "home",
              "common",
              "seaHistory",
              "earthHistory",
              "wineHistory",
              "element",
              "whiteWine",
              "roseWine",
            ],
          },
          to: { namespace: ["espacePro", "mentionsLegales"] },
          sync: true,
          leave(data) {
            return gsap.to(data.current.container, {
              opacity: 0,
              duration: 1,
            });
          },
          enter(data) {
            setNavbarClassic();
            Signals.toggleSound.dispatch(false);

            return gsap.from(data.next.container, {
              opacity: 0,
              delay: 1,
              duration: 1,
            });
          },
          after: () => {},
        },
        {
          name: "transition-to-confirmation",
          sync: true,
          from: {
            namespace: [
              "home",
              "common",
              "seaHistory",
              "earthHistory",
              "wineHistory",
              "element",
              "whiteWine",
              "roseWine",
              "espacePro",
            ],
          },
          to: { namespace: ["confirmation"] },
          leave(data) {
            return gsap.to(data.current.container, {
              opacity: 0,
              duration: 1,
            });
          },
          enter(data) {
            setNavbarClassic();
            Signals.toggleSound.dispatch(false);
            return gsap.from(data.next.container, {
              opacity: 0,
              delay: 1,
              duration: 1,
            });
          },
          after: () => {},
        },
        {
          name: "transition-to-error",
          sync: true,
          from: {
            namespace: [
              "home",
              "common",
              "seaHistory",
              "earthHistory",
              "wineHistory",
              "element",
              "whiteWine",
              "roseWine",
              "espacePro",
              "confirmation",
            ],
          },
          to: { namespace: ["error"] },
          leave(data) {
            return gsap.to(data.current.container, {
              opacity: 0,
              duration: 1,
            });
          },
          enter(data) {
            setNavbarClassic();
            Signals.toggleSound.dispatch(false);
            return gsap.from(data.next.container, {
              opacity: 0,
              delay: 1,
              duration: 1,
            });
          },
          after: () => {},
        },
      ],
    });
  }

  updateView(view) {
    Signals.onTransition.dispatch();

    switch (view) {
      case "seaHistory":
        if (!this.pageSeaHistory) {
          this.pageSeaHistory = new SeaHistory();
        }
        this.view = this.pageSeaHistory;
        break;
      case "earthHistory":
        if (!this.pageEarthHistory) {
          this.pageEarthHistory = new EarthHistory();
        }
        this.view = this.pageEarthHistory;
        break;

      case "wineHistory":
        if (!this.pageWineHistory) {
          this.pageWineHistory = new WineHistory();
        }
        this.view = this.pageWineHistory;
        break;
      case "whiteWine":
        if (!this.pageWhiteWine) {
          this.pageWhiteWine = new WhiteWine();
        }
        this.view = this.pageWhiteWine;
        break;

      case "roseWine":
        if (!this.pageRoseWine) {
          this.pageRoseWine = new RoseWine();
        }
        this.view = this.pageRoseWine;
        break;

      case "element":
        if (!this.pageElement) {
          this.pageElement = new Element();
        }
        this.view = this.pageElement;
        break;
      case "common":
        if (!this.pageCommon) {
          this.pageCommon = new Common();
        }
        this.view = this.pageCommon;
        break;
      case "espacePro":
        if (!this.pageEspacePro) {
          this.pageEspacePro = new EspacePro();
        }
        this.view = this.pageEspacePro;
        break;
      case "mentionsLegales":
        if (!this.pageMentionsLegales) {
          this.pageMentionsLegales = new MentionsLegales();
        }
        this.view = this.pageMentionsLegales;
        break;
      case "confirmation":
        if (!this.pageConfirmation) {
          this.pageConfirmation = new Confirmation();
        }
        this.view = this.pageConfirmation;
        break;
      case "error":
        if (!this.pageError) {
          this.pageError = new Error();
        }
        this.view = this.pageError;
        break;
      default:
        if (!this.pageHome) {
          this.pageHome = new Home();
        }
        this.view = this.pageHome;
        break;
    }
  }
}

const Router = new BarbaRouter();

export default Router;
