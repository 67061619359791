import gsap from "gsap"

class HeroImageHandler {
  constructor() {
    this.hero = document.querySelector(".image_hero")
  }

  animate(position) {
    let pos = position / 10
    this.hero.style.transform = `scale(${gsap.utils.interpolate(1.05,1,pos / 70)}) translateY(${gsap.utils.interpolate("0%", "10%", pos / 70)})`
  }

  destroy() {
    this.hero.style.transform = `scale(1.2) translateY(0)`
  }
}

export default HeroImageHandler
