import * as THREE from 'three'
import App from '../app'
import vertexShader from './../shaders/vertex.glsl'
import fragmentShader from './../shaders/fragment.glsl'
import { isDevice, mobilePlane, desktopPlane } from '../cores/Constants.js'
import { aspectRatio, showButton } from '../cores/Utils.js'
import { VideoTexture } from '../cores/VideoTexture.js'
import gsap from 'gsap'
import { splitAll, toggleTexts, showPoetry, setActiveNav, showTexts, hideTexts } from '../cores/Utils.js'
import Lotties from './Lotties.js'
import Signals from '../cores/Signals'
import Sounds from '../cores/Sounds'
import GUI from './GUI.js'

class Slider {
  constructor(camera) {
    // NEED GLOBALS PARAMS

    this.videos = [document.getElementById('video0'), document.getElementById('video1'), document.getElementById('video2'), document.getElementById('video3')]

    this.videosTextures = []

    this.meshs = new THREE.Group()

    this.animations = Lotties()

    // POSITIONS
    this.meshs.position.x = isDevice ? mobilePlane.meshsOffsetX : desktopPlane.meshsOffsetX
    this.meshs.position.y = isDevice ? mobilePlane.meshsOffsetY : desktopPlane.meshsOffsetY

    this.params = {
      offsetX: isDevice ? mobilePlane.x + mobilePlane.offset : desktopPlane.x + desktopPlane.offset
    }

    this.camera = camera

    this.speed = 0

    this.position = 0
    this.rounded = 0

    this.activeIndex = App.index || 0
    this.lastIndex = 0
    this.pause = 0
    this.interlayer = false
    this.interlayering = false

    // HOVERING
    this.raycaster = new THREE.Raycaster()
    this.currentIntersect = null
    this.opened = false

    this.getDOMElements()

    this.addMeshsToSlides()
    this.addGUIControls()
    this.listenerClick = this.onClick.bind(this)
    this.container.addEventListener('click', this.listenerClick)
    
    
    Sounds.play(this.activeIndex)
  }

  get slides() {
    return this.meshs
  }

  getDOMElements() {
    this.container = document.getElementsByClassName('container--home')[0]
    this.byPass = document.getElementById('byPassAnimation')
    this.nav = document.getElementById('nav-home')
    this.position = -App.index

    this.videos.forEach(video => {
      if (video.paused) {
        video.play()
      }
    })
    
    if(App.index !== null) {
      gsap.fromTo('.webgl', { x: '100vw' }, { x: '0vw', duration: 1, delay: 1.5, ease: 'ease-in' })
      showTexts(this.activeIndex)
      hideTexts(this.lastIndex)
    }
  }

  addMeshsToSlides() {
    // Geometry
    const plane = isDevice ? mobilePlane : desktopPlane
    const geometry = new THREE.PlaneGeometry(plane.x, plane.y, 1, 1)

    // Material
    const rawMaterial = new THREE.ShaderMaterial({
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
      uniforms: {
        uTexture: { value: null },
        uTime: { value: 0 },
        uAlpha: { value: 1 },
        uDistanceFromCenter: { value: 0 },
        uResolution: { type: 'v2', value: new THREE.Vector2(window.innerWidth, window.innerHeight) },
        uScale: { type: 'v2', value: new THREE.Vector2(0, 0) },
        uMouse: { value: new THREE.Vector2(0, 0) },
        uSpeed: { value: 0 },
        uDarker: { value: 0 },
      },
      side: THREE.FrontSide
    })

    this.videos.forEach((video, index) => {
      this.videosTextures[index] = new VideoTexture(video)
      if (index === 0) {
        this.videosTextures[index].current = true
      }
      this.videosTextures[index].minFilter = THREE.LinearFilter
      this.videosTextures[index].magFilter = THREE.LinearFilter
      this.videosTextures[index].format = THREE.RGBFormat

      const material = rawMaterial.clone()
      material.needsUpdate = true
      material.uniforms.uTexture.value = this.videosTextures[index]
      const mesh = new THREE.Mesh(geometry, material)

      mesh.position.x += index * this.params.offsetX

      mesh.dist = 0
      mesh.activeIndex = null
      if (index === 0) {
        mesh.activeIndex = 0
      }

      this.meshs.add(mesh)
    })

    this.resize()
  }

  calcPosition() {
    this.position += this.speed
    this.rounded = Math.round(this.position)
    const diff = this.rounded - this.position
    this.position += Math.sign(diff) * Math.pow(Math.abs(diff), 0.7) * 0.015
    this.position = Math.round((this.position + Number.EPSILON) * 100000) / 100000
  }

  launch() {
    // Initial state
    if(this.activeIndex){
      gsap.to(`#lottie${this.activeIndex +1}`, { alpha: 1, duration: 1, ease: 'ease-in' })
      this.animations[this.activeIndex].play()
      this.pause = true
      this.animations[this.activeIndex].onComplete = () => {
        this.pause = false
        // We set visible the slider
        toggleTexts(this.activeIndex)
        gsap.to('.webgl', { x: '0vw', duration: 1, ease: 'ease-in', onComplete: () => {
          App.index = 0
        } })
      }
    } else {
      gsap.to('#lottie1', { alpha: 1, duration: 1, ease: 'ease-in' })
      this.animations[0].play()
      this.pause = true
      this.animations[0].onComplete = () => {
        this.pause = false
        // We set visible the slider
        toggleTexts(this.activeIndex)
        gsap.to('.webgl', { x: '0vw', duration: 1, ease: 'ease-in', onComplete: () => {
          App.index = 0
        } })
      }
    }

  }

  goTo(i, opening = false) {
    this.position = i
    this.lastIndex = this.activeIndex
    this.activeIndex = Math.abs(i)

    // We reset current hovering state
    this.currentIntersect = null
    this.soundTransition()
    if (opening) {
      this.setActiveSlide()
    }
  }

  onClick(e) {
    if (this.pause || window.drag) {
      return false
    }

    if (this.currentIntersect) {
      this.setNav()
      this.setActiveSlide()
    }
  }

  setNav() {
    this.nav.classList.add('nav-home--active')
  }

  setActiveSlide() {
    this.pause = false
    this.opened = true

    splitAll()

    gsap.to(this.meshs.position, {
      x: isDevice ? mobilePlane.meshsOffsetX / 2 : -desktopPlane.meshsOffsetX / 2,
      y: isDevice ? -mobilePlane.meshsOffsetY / 2 : desktopPlane.meshsOffsetY / 2,
      z: 0.5,
      duration: 1
    })

    setActiveNav(this.activeIndex)
    gsap.to(this.meshs.children[this.activeIndex].material.uniforms.uDarker, {value: .5, duration: 1})
    showPoetry(this.activeIndex, this.lastIndex)
    showButton(this.activeIndex, this.lastIndex)

    App.index = this.activeIndex

    this.container.removeEventListener('click', this.listenerClick)
  }

  resize() {
    this._width = window.innerWidth
    this._height = window.innerHeight
    this._mediaW = 1920
    this._mediaH = 1080

    const aR = aspectRatio(this._mediaW, this._mediaH, this._width, this._height)

    if (aR.width <= this._width) {
      aR.height *= this._width / aR.width
      aR.width *= this._width / aR.width

      if (aR.height <= this._height) {
        aR.width *= this._height / aR.height
        aR.height *= this._height / aR.height
      }
    } else if (aR.height <= this._height) {
      aR.width *= this._height / aR.height
      aR.height *= this._height / aR.height

      if (aR.width <= this._width) {
        aR.height *= this._width / aR.width
        aR.width *= this._width / aR.width
      }
    }
    this.meshs.children.forEach((mesh, i) => {
      
      mesh.material.uniforms.uResolution.value.x = this._width
      mesh.material.uniforms.uResolution.value.y = this._height
      mesh.material.uniforms.uScale.value.x = aR.width
      mesh.material.uniforms.uScale.value.y = aR.height
    })
  }

  soundTransition() {
    if (this.activeIndex !== this.lastIndex) {
      Sounds.play(this.activeIndex, this.lastIndex)
    }
  }

  videoTransition(i) {
    if (this.activeIndex !== this.lastIndex || this.activeIndex === 0) {
      if (i === this.activeIndex || i === this.activeIndex + 1) {
        this.videosTextures[i].current = true
      } else {
        this.videosTextures[i].current = false
      }
    }
  }

  update() {
    this.getDOMElements()
  }

  animate(mouse) {
    /**
     *
     * We adjust SPEED
     *
     */

    const webGlElement = document.querySelector('.webgl')
    if (webGlElement) {
      if (this.currentIntersect) {
        webGlElement.style.cursor = 'pointer'
      } else {
        webGlElement.style.cursor = 'auto'
      }
    }

    this.speed = window.speed / 10

    if (this.pause || (this.interlayer == false && this.activeIndex > 0)) {
      this.speed *= 0.0005
    }
    // this.speed = window.speed.toFixed(8)
    // Left and Right limits
    if ((this.activeIndex == 0 && this.speed > 0) || (this.activeIndex == this.meshs.children.length - 1 && this.speed < 0)) {
      this.speed *= 0.01
    } else {
      this.speed *= 1.2
    }

    this.speed = this.opened ? 0 : this.speed

    this.calcPosition()

    this.meshs.children.forEach((mesh, i) => {
      this.videoTransition(i)

      mesh.dist = Math.min(Math.abs(this.position + i), 1)
      mesh.dist = 1 - mesh.dist ** 2
      // Calc position of meshes depending on their index and global offset
      this.pos = i * this.params.offsetX + this.position * this.params.offsetX

      // We launch a prev/next slidding
      if (mesh.dist > 0.95 && this.activeIndex !== i) {
        this.pause = true
        this.interlayer = false
        this.lastIndex = this.activeIndex
        this.activeIndex = i
        mesh.activeIndex = i
        toggleTexts(null, this.lastIndex)
        gsap.fromTo(`#lottie${this.lastIndex + 1}`, { alpha: 1 }, { alpha: 0, duration: 0.5, ease: 'ease-out' })
        gsap.fromTo(`#lottie${this.activeIndex + 1}`, { alpha: 0 }, { alpha: 1, duration: 0.5, ease: 'ease-in' })
        // We slide to the RIGHT -->
        if (this.speed < 0) {
          // We set byPass button available
          gsap.to(this.byPass, { alpha: 1 })
          this.byPass.addEventListener('click', e => {
            this.animations[i].stop()
            this.pause = false
            toggleTexts(this.activeIndex, this.lastIndex)
            gsap.to(e.target, { alpha: 0 })
          })
          //call sound for each plane
          this.soundTransition()
          this.animations[i].stop()
          this.animations[i].play()
          this.animations[i].onComplete = () => {
            this.pause = false
            toggleTexts(this.activeIndex, this.lastIndex)
            gsap.to(this.byPass, { alpha: 0 })
          }
          // We slide to the LEFT <--
        } else {
          this.pause = false
          toggleTexts(this.activeIndex, this.lastIndex)
          //call sound for each plane
          this.soundTransition()
        }
      }
      // We slide to the LEFT <--
      if (this.activeIndex < this.lastIndex && this.speed > 0 && this.interlayer == false) {
        mesh.position.x = this.pos
        this.interlayer = true
        // We slide to the RIGHT -->
      } else {
        // Previous mesh continue to progress during interlayer
        if (this.pause == true && i == this.lastIndex && mesh.position.x >= -5) {
          mesh.position.x += this.pos / 30
        }
        // POST animation and BEFORE next step
        // Fire ONCE to restore all positions
        if (this.activeIndex > 0 && this.pause == false && i >= this.activeIndex && this.interlayer == false && this.interlayering == false) {
          // We active interlayer after positions are restored
          if (i == this.meshs.children.length - 1) {
            this.interlayering = true
            gsap.to(mesh.position, {
              x: this.pos,
              duration: 1,
              ease: 'ease-in',
              onComplete: () => {
                // To prevent delay of smooth scroll
                this.interlayer = true
                this.interlayering = false
              }
            })
          } else {
            gsap.to(mesh.position, { x: this.pos, duration: 1, ease: 'ease-in' })
          }
        }
        // POST and PRE Animation - from right to left <--
        if (this.interlayer == true && this.pause == false) {
          if (i < this.activeIndex) {
            mesh.position.x += this.pos
          } else if (i > this.activeIndex) {
            mesh.position.x = this.pos
            // FIX HARD REPLACEMENT HERE
            if (i == this.activeIndex + 1) {
              mesh.position.x = Math.max(this.pos, this.params.offsetX)
            }

            if (i === this.activeIndex + 2) {
              mesh.position.x = this.params.offsetX * 2
            }
          } else {
            mesh.position.x = this.pos
          }
        }
      }
    })

    if (!this.opened) {
      // Start position
      if (this.activeIndex == 0) {
        if (this.speed > 0) {
          this.meshs.children[0].position.x = this.position * this.params.offsetX

          // scroll vers l'arrière
          this.meshs.children[1].position.x = Math.max(this.params.offsetX + this.position * this.params.offsetX, this.params.offsetX)
        }

        if (this.speed < 0) {
          this.meshs.children[0].position.x = this.position * this.params.offsetX
          this.meshs.children[1].position.x = Math.max(this.params.offsetX + this.position * this.params.offsetX, this.params.offsetX)
        }
      }
      // Position intermediate - We slide to the RIGHT
      if (this.interlayer == true && this.speed < 0 && this.pause == false) {
        this.meshs.children[this.activeIndex].position.x = this.activeIndex * this.params.offsetX + this.position * this.params.offsetX
      }
    }

    /**
     *
     * We check HOVERING
     *
     */
    // update the picking ray with the camera and mouse position
    this.raycaster.setFromCamera(mouse, this.camera)
    const intersects = this.raycaster.intersectObjects(this.meshs.children)

    // We detect hovering
    if (intersects.length) {
      if (!this.currentIntersect && intersects[0].object.activeIndex === this.activeIndex && (this.interlayer == true || this.activeIndex == 0)) {
        const oIntersected = intersects[0].object.scale
        !isDevice && gsap.to(oIntersected, { x: 1.025, y: 1.025, duration: 0.5 })
      }
      this.currentIntersect = intersects[0]
    } else {
      // We restore slide previously hovered to original size
      if (this.currentIntersect) {
        const oIntersected = this.currentIntersect.object.scale
        gsap.to(oIntersected, { x: 1, y: 1, duration: 0.5 })
      }
      // We reset current hovering state
      this.currentIntersect = null
    }
  }

  destroy() {
    this.speed = 0
    this.container.removeEventListener('click', this.listenerClick)
  }

  addGUIControls() {
    // GUI.add(this.meshs.position, 'x')
    //   .min(-0.5)
    //   .max(0.5)
    //   .step(0.01)
    //   .name('group position x')
    // GUI.add(this, 'position')
    //   .min(-3)
    //   .max(0)
    //   .step(1)
    //   .name('navigation')
  }
}

export default Slider
