import VirtualScroll from "virtual-scroll";

import { isDevice } from "./Constants";
import Signals from "./Signals";
import Lethargy from "./Lethargy";

function Scroller() {
  //options
  const options = {
    mouseMultiplier: 0.008,
    firefoxMultiplier: 50,
    touchMultiplier: 0.05,
    dragMultiplier: 0.5,
  };

  const lethargy = new Lethargy(7, 10, 0.05, 0.05);
  let dragEnable = false;
  let dragMouse = false;
  let drag = { x: 0, y: 0 };
  let eventDrag = {
    x: 0,
    y: 0,
    deltaX: 0,
    deltaY: 0,
  };

  const scrollVS = new VirtualScroll(options);
  window.speed = 0;
  window.speedX = 0;
  window.speedY = 0;

  scrollVS.on((event) => {
    dragEnable = true;
    if (isDevice) {
      window.speed = event.deltaX;
      window.speedX = event.deltaX;
      window.speedY = event.deltaY;
    } else {
      window.speed = event.deltaY;
      window.speedX = event.deltaY;
      window.speedY = event.deltaY;
    }

    Signals.onScroll.dispatch();

    setTimeout(() => {
      window.drag = false;
    }, 500);
  });

  if (!isDevice) {
    window.addEventListener("mousedown", (e) => {
      if (e.target.type !== "email") {
        e.preventDefault();
        dragEnable = true;
        dragMouse = true;
        var t = e;
        // var t = e.targetTouches ? e.targetTouches[0] : e
        drag.x = t.pageX;
        drag.y = t.pageY;
      }
    });

    window.addEventListener("mousemove", (e) => {
      if (dragEnable && dragMouse) {
        window.drag = true;
        e.preventDefault();
        e.stopPropagation();
        var t = e;
        // var t = e.targetTouches ? e.targetTouches[0] : e

        eventDrag.deltaX = (t.pageX - drag.x) * options.dragMultiplier;
        eventDrag.deltaY = (t.pageY - drag.y) * options.dragMultiplier;

        window.speed = eventDrag.deltaX;
        window.speedX = eventDrag.deltaX;
        window.speedY = eventDrag.deltaY;

        drag.x = t.pageX;
        drag.y = t.pageY;
      }
    });

    window.addEventListener("mouseup", (e) => {
      e.preventDefault();
      dragEnable = false;
      dragMouse = false;
      setTimeout(() => {
        window.drag = false;
      }, 500);
    });
  }
}

export default Scroller;
