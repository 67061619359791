import { Howl, Howler } from 'howler'
import Signals from './Signals'

Howler.unload()

const sounds = [
  new Howl({ src: ['/sounds/sound0.mp3'], loop:true, volume: 0 }),
  new Howl({ src: ['/sounds/sound1.mp3'], loop:true, volume: 0 }),
  new Howl({ src: ['/sounds/sound2.mp3'], loop:true, volume: 0 }),
  new Howl({ src: ['/sounds/sound3.mp3'], loop:true, volume: 0 })
]

const soundProcesses = sounds.map(sound => sound.play())

const play = (index, lastIndex) => {
  if (lastIndex === undefined) { // if no lastIndex, stop all others
    sounds.forEach((sound, i) => {
      if (i === index) return
      
      if (sound.playing(soundProcesses[i])) {
        sound.once("fade", () => {
          sound.stop()
        })
        sound.fade(1, 0, 1000, soundProcesses[i])
      }
    })
  } else {
    if (sounds[lastIndex].playing(soundProcesses[lastIndex])) {
      sounds[lastIndex].once("fade", () => {
        sounds[lastIndex].stop()
      }, soundProcesses[lastIndex])
      sounds[lastIndex].fade(1, 0, 1000, soundProcesses[lastIndex])
    }
  }
  
  sounds[index].stop(soundProcesses[index])
  soundProcesses[index] = sounds[index].play()
  sounds[index].fade(0, 1, 1000, soundProcesses[index])
}

const toggleSound = (enabled) => {
  Howler.volume(enabled ? 1 : 0)
}

Signals.soundChange.add(toggleSound)

toggleSound(window.States.soundEnabled)

export default { sounds, soundProcesses, play, toggleSound }
