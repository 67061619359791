import axios from "axios";

class ConfigService {
  static async loadConfig() {
    const response = await axios.get("/env.json");
    return response.data;
  }
}

export default ConfigService;
