import Scroller from "../cores/Scroller";
import barba from "@barba/core";
import TextRevealHandler from "../cores/TextRevealHandler";
import ImageParallaxHandler from "../cores/ImageParallaxHandler";
import ScrollHandler from "../cores/ScrollHandler";
import { isEN } from "../cores/Utils.js";
import NewsletterHandler from "../cores/NewsletterHandler";

const routes = require("../routes.json");

class RoseWine {
  constructor() {
    this.positionX = 0;
    this.positionY = this.rounded = 0;
    this.activeIndex = 0;
    this.pause = false;

    this.transition = false;

    this.speed = 0;

    this.minY = 0;
    Scroller();

    this.listenerClick = this.goTo.bind(this);
    this.textRevealHandler = new TextRevealHandler();
    this.imageParallaxHandler = new ImageParallaxHandler();
  }

  getDOMElements() {
    this.wrapper = document.querySelector(".wrapperRoseWine");
    this.panels = document.querySelectorAll(".panel");
    this.title = document.querySelector(".element__title");
    this.img = document.querySelector(".panel__imgscale");
    this.desc = document.querySelector(".element__desc");
    this.aside = document.querySelector(".element__aside");
    this.buttons = document.querySelectorAll(".button-to");
    this.panels = document.querySelectorAll(".panel");
    this.mask = document.querySelector("#rect");
    this.svg = document.querySelector(".panel__containerMask");
  }

  goTo(e) {
    // TO DO e.srcElement.getAttribute('data-index')
    barba.go("index.html");
  }

  updateAnimation() {
    this.textRevealHandler.animate();
    Scroller();

    this.listenerClick = this.goTo.bind(this);

    this.textRevealHandler = new TextRevealHandler();

    this.imageParallaxHandler = new ImageParallaxHandler();
  }

  update() {
    this.getDOMElements();
    this.updateAnimation();
    this.handleNext();
    this.handleNewsletter();
  }

  handleNewsletter() {
    this.newsletterHandler = new NewsletterHandler();
  }

  handleNext() {
    const nextElement = document.querySelector("#nextRoseWine");
    // Video Observer
    const optionsNext = {
      root: null,
      rootMargin: "0px",
      threshold: 0.25,
    };

    const observerNext = new IntersectionObserver((elements) => {
      elements.forEach((element) => {
        if (element.isIntersecting ? true : false) {
          isEN()
            ? barba.go(`${routes["domain"]["en"]}#vins`)
            : barba.go(`${routes["domain"]["fr"]}#vins`);
        }
      });
    }, optionsNext);
    observerNext.observe(nextElement);
  }

  animate() {
    ScrollHandler.call(this);

    this.wrapper.style.transform = `translate3d(0px, ${-this
      .positionY}px, 0px)`;

    if (this.textRevealHandler) {
    }

    if (this.imageParallaxHandler) {
      this.imageParallaxHandler.animate(this.positionY);
    }

    // Go back to initial position on tiny scroll --> BETWEEN 2 VALUES [-1, -2] for example or [2,3]
  }

  destroy() {
    this.positionX = 0;
    this.positionY = this.rounded = 0;
    this.activeIndex = 0;
    this.pause = false;

    this.transition = false;

    this.speed = 0;

    this.minY = 0;
  }
}

export default RoseWine;
