import Scroller from '../cores/Scroller'

export default class MentionsLegales {
  constructor() {
    Scroller()
  }
  
  animate() {}

  update() {}
  
  destroy() {}
}
