import * as THREE from "three";
import gsap from "gsap";
import raf from "raf";
import Loader from "./cores/Loader";
import Router from "./cores/Router";
import AgeCheck from "./cores/AgeCheck";
import FirefoxAlert from "./cores/FirefoxAlert";
import lottie from "lottie-web";
import { isMobile, isFirefox } from "./cores/Utils.js";
import device from "current-device";

class Main {
  init() {
    this.index = null;
    this.currentView = "";
    this.pause = false;
    this.clock = new THREE.Clock();
    //60 fps
    this.interval = 1 / 60;
    this.delta = 0;

    // animations
    this.finish = false;

    this.animation = lottie.loadAnimation({
      container: document.getElementById("lottie0"),
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
        progressiveLoad: true,
      },
      loop: false,
      autoplay: false,
      path: "/lotties/loader.json",
    });

    // Check mobile orientation
    if (device.type === "mobile" || device.type === "tablet") {
      if (
        window.screen.orientation &&
        typeof window.screen.orientation.lock === "function"
      ) {
        window.screen.orientation.lock("portrait").then(
          (success) => {},
          (failure) => {
            this.checkDeviceOrientation();
          },
        );
      } else {
        this.checkDeviceOrientation();
      }
    }

    this.firefoxAlert = new FirefoxAlert();

    if (isFirefox()) {
      // this.firefoxAlert.launch()
      // Signals.firefoxConfirmed.add(() => {
      //   this.checkAge()
      // })
      this.firefoxAlert.cancelled();
      this.checkAge();
    } else {
      this.firefoxAlert.cancelled();
      this.checkAge();
    }

    this.createLoaderManager();
  }

  checkDeviceOrientation() {
    const mobileLandscapeAlert = document.getElementById("mobile-landscape");
    let mdo = window.matchMedia("(orientation: landscape)");

    if (mdo.matches) {
      mobileLandscapeAlert.style.display = "flex";
    }

    mdo.addEventListener("change", (m) => {
      if (mdo.matches) {
        mobileLandscapeAlert.style.display = "flex";
      } else {
        mobileLandscapeAlert.style.display = "none";
      }
    });
  }

  checkAge() {
    new AgeCheck();
  }

  handleLocationAnimation() {
    const counters = document.querySelectorAll(".parent-increment");

    counters.forEach((count) => {
      count.classList.add("activeSpan");
    });

    gsap.to("#lottie0", { opacity: 1, duration: 0.5 });

    this.animation.play();

    setTimeout(() => {
      gsap.to("#loader", {
        autoAlpha: true,
        opacity: 0,
        duration: 1,
        onComplete: () => {
          Signals.onAssetsLoaded.dispatch();
        },
      });
    }, 6000);
  }

  updateHandler(loading) {
    gsap.to("#progress", {
      value: Math.round(loading),
    });

    if (Math.round(loading) === 100) {
      this.handleLocationAnimation();
      this.finish = true;
    }
  }

  createLoaderManager() {
    Signals.onRouted.add(() => {
      if (Router.namespace === "home") {
        gsap.to("#loader-intro", { autoAlpha: true, opacity: 0, duration: 1 });
        gsap.set(".webgl", { x: "100vw" });
      }
    });

    Loader.addEventListener("onAssetsLoading", () => {
      this.updateHandler(Loader.percentLoaded);
    });
  }

  paused() {
    this.pause = true;
  }

  resumed() {
    this.pause = false;
    this.tick();
  }

  update(view) {
    if (this.currentView) {
      //We remove instance of old view
      this.currentView.destroy();
    }

    this.currentView = view;

    this.currentView.update();

    if (!this.pause) {
      this.tick();
    }
  }

  tick() {
    this.delta += this.clock.getDelta();
    window.speedX *= 0.8;
    window.speedY *= 0.8;
    window.speed *= 0.8;

    if (this.delta > this.interval) {
      if (!this.pause) {
        this.currentView.animate();
      }

      this.delta = this.delta % this.interval;
    }

    raf(this.tick.bind(this));
  }
}

const App = new Main();

export default App;
