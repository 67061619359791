export default class AgeCheck {
  constructor() {
    this.container = document.getElementsByClassName('AgeCheck__container')[0]

    if (!!sessionStorage.getItem('AGE_CHECK_DONE')) {
      this.container.style.display = 'none'
      return
    }
    
    this.question = document.getElementsByClassName('AgeCheck__question')[0]
    this.answers = document.getElementsByClassName('AgeCheck__answers')[0]
    this.yesAnswer = document.getElementById('AgeCheck__yes')
    this.noAnswer = document.getElementById('AgeCheck__no')
    
    this.yesAnswer.addEventListener('click', () => {
      this.container.style.display = 'none'
      sessionStorage.setItem('AGE_CHECK_DONE', true)
    })
    
    this.noAnswer.addEventListener('click', () => {
      this.question.innerHTML = __('ageCheck.refused')
      this.answers.style.display = 'none'
    })
  }
}
