// import VirtualScroll from 'virtual-scroll';
// 
// import {isDevice} from './Constants';
// import Signals from './Signals'
// import Lethargy from './Lethargy'
import { debounce } from 'lodash'
import NormalizeWheel from 'normalize-wheel'
import Lethargy from './Lethargy'

function ScrollerElements(stickToClosest) {
  //options
  const options = {
    mouseMultiplier: 0.0008,
    firefoxMultiplier: 40,
    touchMultiplier: 0.01,
    dragMultiplier: 0.0025,
  }

  this.wrapper = document.querySelector(".element__wrapper")
  const lethargy = new Lethargy(7, 10, 0.05);
  
  this.debounceStickToClosest = debounce(stickToClosest, 100)
  
  this.start = null
  this.isDown = false
  
  this.scroll = {
    ease: 0.05,
    position: 0,
    target: 0,
    current: 0,
  }

  let dragEnable = false;
  let dragMouse = false
  
  const onTouchDown = (event) => {
    event.preventDefault();
    this.isDown = true
   
    this.scroll.position = this.scroll.current
    this.start = event.touches ? event.touches[0].clientX : event.clientX
  }
   
  const onTouchMove = (event) => {

    const supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;

    const multiplier = supportsTouch ? options.touchMultiplier : options.dragMultiplier

    event.preventDefault();
    if (!this.isDown) return
   
    const x = event.touches ? event.touches[0].clientX : event.clientX
    const distance = (this.start - x) * multiplier
   
    this.scroll.target = this.scroll.position + distance
  }
   
  const onTouchUp = (event) => {
    event.preventDefault()
    this.isDown = false
    stickToClosest()
  }
  
  const onWheel = (event) => {
    if (lethargy.check(event)) {

    }

    const normalized = NormalizeWheel(event)
    const speed = normalized.pixelY
   
    this.scroll.target += speed * options.mouseMultiplier
    this.debounceStickToClosest()
  }
  
  this.wrapper.addEventListener('mousewheel', onWheel)
  this.wrapper.addEventListener('wheel', onWheel)

  this.wrapper.addEventListener('mousedown', onTouchDown)
  this.wrapper.addEventListener('mousemove', onTouchMove)
  this.wrapper.addEventListener('mouseup', onTouchUp)

  this.wrapper.addEventListener('touchstart', onTouchDown)
  this.wrapper.addEventListener('touchmove', onTouchMove)
  this.wrapper.addEventListener('touchend', onTouchUp)
  
  this.removeEventListeners = () => {
    this.wrapper.removeEventListener('mousewheel', onWheel)
    this.wrapper.removeEventListener('wheel', onWheel)

    this.wrapper.removeEventListener('mousedown', onTouchDown)
    this.wrapper.removeEventListener('mousemove', onTouchMove)
    this.wrapper.removeEventListener('mouseup', onTouchUp)

    this.wrapper.removeEventListener('touchstart', onTouchDown)
    this.wrapper.removeEventListener('touchmove', onTouchMove)
    this.wrapper.removeEventListener('touchend', onTouchUp)
  }
}

export default ScrollerElements
