import lottie from 'lottie-web'

export default function getLotties(){
    const animation = lottie.loadAnimation({
        container: document.getElementById('lottie1'),
        renderer: 'svg',
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
          progressiveLoad: true,
        },
        loop: false,
        autoplay: false,
        path: '/lotties/mer.json'
      })
      const animation1 = lottie.loadAnimation({
        container: document.getElementById('lottie2'),
        renderer: 'svg',
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
          progressiveLoad: true,
        },
        loop: false,
        autoplay: false,
        path: '/lotties/terre.json'
      })
      const animation2 = lottie.loadAnimation({
        container: document.getElementById('lottie3'),
        renderer: 'svg',
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
          progressiveLoad: false,
        },
        loop: false,
        autoplay: false,
        path: '/lotties/vent.json'
      })
      const animation3 = lottie.loadAnimation({
        container: document.getElementById('lottie4'),
        renderer: 'svg',
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
          progressiveLoad: false,
        },
        loop: false,
        autoplay: false,
        path: '/lotties/lumiere.json'
      })
      return [animation, animation1, animation2, animation3]
}