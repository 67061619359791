import Scroller from "../cores/Scroller";
import NewsletterHandler from "../cores/NewsletterHandler";

export default class EspacePro {
  constructor() {
    Scroller();
  }

  animate() {}

  update() {
    this.newsletterHandler = new NewsletterHandler();
  }

  destroy() {}
}
