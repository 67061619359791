import resources from './ressources.json'
import lottie from 'lottie-web'
import Signals from "./Signals"
import States from './States'
import EventTarget from '@ungap/event-target'

class AssetLoader extends EventTarget {
  constructor() {
    super()
    this.assetsToLoad = 0
    this.assetsLoaded = 0
    this._startLoading()
    this.animationSound = lottie.loadAnimation({
      container: document.querySelector('.loader__sound_button'),
      renderer: 'svg',
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet',
        progressiveLoad: false,
      },
      loop: true,
      autoplay: window.States.soundEnabled,
      path: '/lotties/sound.json'
    })
    
    this.soundButton = document.querySelector(".loader__sound_button")
    
    if (this.soundButton) this.soundButton.onclick = () => {
      Signals.toggleSound.dispatch()
    }
    Signals.soundChange.add((value) => this.handleSoundChange(value))
  }
  
  handleSoundChange(enabled) {
    if (enabled) {
      this.animationSound.play()
    } else {
      this.animationSound.stop()
    }
  }

  _startLoading() {
    this.assetsToLoad += resources.images.length
    this.assetsToLoad += resources.videos.length

    if (typeof resources.images !== 'undefined' && resources.images.length > 0) {
      this.loadImages()
    }

    if (typeof resources.videos !== 'undefined' && resources.videos.length > 0) {
      this.loadVideos()
    }

    if (this.assetsToLoad === 0) this.dispatchEvent(new Event('onAssetsLoaded'))
  }

  onLoading() {
    this.assetsLoaded += 1
    this.percentLoaded = (this.assetsLoaded / this.assetsToLoad) * 100

    if (this.percentLoaded > 100) {
      this.percentLoaded = 100
    }

    this.dispatchEvent(new Event('onAssetsLoading'))

    if (this.percentLoaded === 100) this.dispatchEvent(new Event('onAssetsLoaded'))
  }

  loadImages() {
    const images = resources.images

    for (let i = 0; i < images.length; i += 1) {
      this.loadImage(images[i]).then(() => this.onLoading(), err => {})
    }
  }

  loadImage(media) {
    return new Promise((resolve, reject) => {
      const image = new Image()
      image.alt = media.description

      image.onload = () => {
        resolve({ id: media.id, media: image })
      }

      image.onerror = () => {
        resolve(`Erreur lors du chargement de l'image : ${image}`)
      }

      image.src = media
    })
  }

  loadVideos() {
    const videos = resources.videos

    for (let i = 0; i < videos.length; i += 1) {
      this.loadVideo(videos[i]).then(() => this.onLoading(), err => {})
    }
  }

  loadVideo(media) {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video')
      video.preload = 'auto'

      video.oncanplaythrough = () => {
        resolve({ id: media.id, media: video })
      }

      video.oncanplay = () => {
        resolve({ id: media.id, media: video })
      }

      video.onloadedmetadata = () => {
        resolve({ id: media.id, media: video })
      }

      video.onloadeddata = () => {
        resolve({ id: media.id, media: video })
      }

      const interval = setInterval(() => {
        if (video.readyState === 4) {
          clearInterval(interval)
          resolve({ id: media.id, media: video })
        }
      }, 100)

      video.onerror = () => {
        reject(`Une erreur est survenue lors du chargement de la video : ${video}`)
      }

      video.src = media
    })
  }
}

const Loader = new AssetLoader()

export default Loader
