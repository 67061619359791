import gsap from "gsap/gsap-core"

class ImageParallaxHandler {
  constructor() {
    this.observedElements = []

    this.MAX_DELTA = 0.4

    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: [0.025, 0.05, 0.075, 0.1, 0.25],
    }

    this.elements = document.querySelectorAll(".image__parallax")
    this.elements.forEach((element, index) => {
      this.observedElements.push({ elem: element })
      this.observedElements[index].baseOffset = element.getBoundingClientRect().top + (element.getBoundingClientRect().height / 2)
      this.observedElements[index].currentOffset = this.observedElements[
        index
      ].baseOffset
      gsap.to(element, {y: -20 * (window.innerHeight / 100)})
      // element.style.transform = `translate3d(0px,-${this.observedElements[index].baseOffset}px , 0px)`
      const observer = new IntersectionObserver((resElements) => {
        this.observedElements[index].isVisible = resElements[0].isIntersecting
        this.observedElements[index].isVisible = resElements[0].intersectionRatio
      }, observerOptions)
      observer.observe(element)
    })
  }

  animate(scroll) {

    if (this.observedElements[0])
      this.observedElements.forEach((observedElement) => {
        if (observedElement.isVisible) {

          // const distance = (1 - ((observedElement.baseOffset - scroll) * (100 / window.innerHeight)))


          const transformer = gsap.utils.pipe(
            gsap.utils.mapRange(observedElement.baseOffset - window.innerHeight, observedElement.baseOffset + window.innerHeight, 0, 1),
            gsap.utils.interpolate(-10,10),
            gsap.utils.clamp(-10,10)
          );

          gsap.to(observedElement.elem, {yPercent: -transformer(scroll) })
        }
      })
  }
}

export default ImageParallaxHandler
