const isDevice = 'ontouchstart' in window;

const phonePlane = {
    x: 2.75,
    y: 1.1,
    offset: 0.08, 
    meshsOffsetX: 0,
    meshsOffsetY: -0.2,
}

const tabletPlane = {
    x: 2.65,
    y: 1,
    offset: 0.08, 
    meshsOffsetX: 0,
    meshsOffsetY: -0.1,
}

const mobilePlane = window.matchMedia("(max-width: 1200px) and (min-width: 601px)").matches ? tabletPlane : phonePlane

const desktopPlane = {
    x: 2.,
    y: 1.25,
    offset: 0.05,
    meshsOffsetX: 0.25,
    meshsOffsetY: 0,
}

const imageAspectRatio = 1920 / 1080



export {mobilePlane, desktopPlane, isDevice, imageAspectRatio}