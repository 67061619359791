import { RGBFormat, LinearFilter } from 'three/src/constants';
import { Texture } from 'three/src/textures/Texture';

class VideoTexture extends Texture {

	constructor( video, mapping, wrapS, wrapT, magFilter, minFilter, format, type, anisotropy ) {

		super( video, mapping, wrapS, wrapT, magFilter, minFilter, format, type, anisotropy );

		this.format = format !== undefined ? format : RGBFormat;

		this.minFilter = minFilter !== undefined ? minFilter : LinearFilter;
		this.magFilter = magFilter !== undefined ? magFilter : LinearFilter;

		this.generateMipmaps = false;

		this.current = false;

		const scope = this;

		function updateVideo() {

			scope.needsUpdate = true;
			video.requestVideoFrameCallback( updateVideo );

		}

		if ( 'requestVideoFrameCallback' in video ) {

			video.requestVideoFrameCallback( updateVideo );

		}

	}

	clone() {

		return new this.constructor( this.image ).copy( this );

	}

	update() {
		const video = this.image;
		const hasVideoFrameCallback = 'requestVideoFrameCallback' in video;

		setInterval( () => {

			if ( hasVideoFrameCallback === false && video.readyState >= video.HAVE_CURRENT_DATA ) {
				
				if(this.current) {
					this.needsUpdate = true;
				} else {
					this.needsUpdate = false;
				}

			}

		}, 1000 / 29.97);

	}

}

VideoTexture.prototype.isVideoTexture = true;

export { VideoTexture };