import Scroller from "../cores/Scroller";

class Confirmation {
  constructor() {
    Scroller();

    this.getDOMElements();
    this.extractRedirectUrl();
    this.defineMessage();
    this.setTimeout();
    this.defineEventListener();
  }

  extractRedirectUrl() {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    this.redirectUrl = params.get("redirectUrl")
      ? decodeURIComponent(params.get("redirectUrl"))
      : null;
  }

  getDOMElements() {
    this.confirmation_page = document.querySelector(".confirmation");
    this.success_message = document.querySelector(
      ".confirmation__message--success",
    );
    this.error_message = document.querySelector(
      ".confirmation__message--error",
    );
    this.redirectLink = document.querySelector(".confirmation__link");
  }

  animate() {}

  update() {
    this.getDOMElements();
    this.defineMessage();
    this.setTimeout();
    this.defineEventListener();
  }

  destroy() {
    clearTimeout(this.redirectTimer);
  }

  defineMessage() {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const success = params.get("success");

    if (success === "true") {
      this.success_message.classList.remove("hidden");
      this.error_message.classList.add("hidden");
      this.confirmation_page.classList.add("confirmation--success");
    } else {
      this.success_message.classList.add("hidden");
      this.error_message.classList.remove("hidden");
      this.confirmation_page.classList.remove("confirmation--success");
      this.confirmation_page.classList.add("confirmation--error");
    }
  }

  setTimeout() {
    if (this.redirectUrl) {
      this.redirectTimer = setTimeout(() => this.redirect(), 15000);
    }
  }

  redirect() {
    if (this.redirectUrl) {
      window.location.href = this.redirectUrl;
    }
  }

  defineEventListener() {
    this.redirectLink.addEventListener("click", (e) => {
      e.preventDefault();
      clearTimeout(this.redirectTimer);
      this.redirect();
    });
  }
}

export default Confirmation;
