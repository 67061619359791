import * as dat from 'dat.gui'

const gui = new dat.GUI({
  preset: 'Desktop',
  remembered: {
    Desktop: {
      '0': 'x:8'
    }
  }
})

gui.hide()

export default gui
