import Scroller from "../cores/Scroller";

class Error {
  constructor() {
    Scroller();
  }

  animate() {}

  update() {}

  destroy() {}
}

export default Error;
