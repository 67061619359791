import * as THREE from 'three'
import Signals from '../cores/Signals'
import Scroller from '../cores/Scroller'
import Slider from '../components/Slider.js'
import { isMobile } from '../cores/Utils.js'

class Home {
  constructor() {
    this.sizes = {
      width: window.innerWidth,
      height: window.innerHeight
    }

    this.mouse = new THREE.Vector2(0, -5)

    this.listenerClick = this.goTo.bind(this)

    this.listenerMouse = this.onMouseMove.bind(this)

    if( !isMobile() ){
      this.listenerResize = this.onResize.bind(this)
    }
  }

  getDOMElements() {
    this.canvas = document.querySelector('canvas.webgl')
    this.nav = document.querySelector('.home__nav')
    this.buttons = document.querySelectorAll('.button-to')
  }

  setAppear() {
    // Here we can play start animation on view update. See Element component
    // --------
    this.setEvents()
  }

  setEvents() {
    this.buttons.forEach(button => {
      button.addEventListener('click', this.listenerClick)
    })

    Scroller()
  }

  goTo(e) {
    const to = e.srcElement.getAttribute('data-to')

    if (to.length > 0) {
      this.slider.goTo(parseInt(to, 10), true)
    }
  }

  createScene() {
    // Scroller()
    this.scene = new THREE.Scene()
    this.renderer = new THREE.WebGLRenderer({
      powerPreference: 'high-performance',
      canvas: this.canvas,
      alpha: true
    })
    this.renderer.background = new THREE.Color(0x000000)
    this.renderer.setSize(this.sizes.width, this.sizes.height)
    this.renderer.setPixelRatio(1)
  }

  createCamera() {
    const fov = 75
    const aspect = 2 // the canvas default
    const near = 0.1
    const far = 5
    const aspectRatio = this.sizes.width / this.sizes.height
    this.camera = new THREE.PerspectiveCamera(fov, aspect, near, far)
    this.camera.updateProjectionMatrix()
    this.camera.position.set(0, 0, 1)
    this.scene.add(this.camera)
  }

  createSlider() {
    this.slider = new Slider(this.camera)
    this.scene.add(this.slider.meshs)
  }

  onResize() {
    this.sizes.width = window.innerWidth
    this.sizes.height = window.innerHeight

    // Update camera
    this.camera.updateProjectionMatrix()
    // Update renderer
    this.renderer.setSize(this.sizes.width, this.sizes.height)
    this.renderer.setPixelRatio(1)

    //scale texture
    this.slider.resize()
  }

  onMouseMove(e) {
    // calculate mouse position in normalized device coordinates
    // (-1 to +1) for both components
    this.mouse.x = (e.clientX / window.innerWidth) * 2 - 1
    this.mouse.y = -(e.clientY / window.innerHeight) * 2 + 1
  }

  update() {
    this.getDOMElements()
    this.setAppear()

    this.createScene()
    this.createCamera()
    this.createSlider()

    // this.onResize()

    if (window.States.assetsLoaded) {
      this.slider.launch()
    } else {
      Signals.onAssetsLoaded.add(() => {
        this.slider.launch()
      })
    }

    window.addEventListener('mousemove', this.listenerMouse, false)
    window.addEventListener('resize', this.listenerResize)
  }

  animate() {
    if (this.slider) this.slider.animate(this.mouse)
    if (this.renderer) this.renderer.render(this.scene, this.camera)
  }

  destroy() {
    this.scene = null
    this.renderer = null

    this.slider.destroy()

    window.removeEventListener('mousemove', this.listenerMouse, false)
    window.removeEventListener('resize', this.listenerResize)
  }
}

export default Home
