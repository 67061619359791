import Signal from 'min-signal'

if (typeof window === 'undefined') {
  global.window = {}
}

class Signals {
  constructor() {
    // Assets
    this.onAssetsLoading = new Signal()
    this.onAssetsLoaded = new Signal()
    this.speed = new Signal()
    this.onTransition = new Signal()
    this.onScroll = new Signal()
    this.toggleSound = new Signal()
    this.soundChange = new Signal()
    this.onRouted = new Signal()
    this.firefoxConfirmed = new Signal()
  }
}

window.Signals = new Signals()

export default window.Signals
