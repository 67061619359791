import Scroller from "../cores/Scroller"
import barba from "@barba/core"
import ImageParallaxHandler from "../cores/ImageParallaxHandler"
import TextRevealHandler from "../cores/TextRevealHandler"
import HeroImageHandler from "../cores/HeroImageHandler"
import HistoryImagesHandler from "../cores/HistoryImagesHandler"
import { isMobile, isEN } from "../cores/Utils"
import ScrollHandler from "../cores/ScrollHandler"

const routes = require('../routes.json')

class SeaHistory {
  constructor() {
    this.positionX = 0
    this.positionY = this.rounded = 0
    this.activeIndex = 0
    this.pause = false

    this.transition = false

    this.speed = 0

    this.minY = 0
    Scroller()

    this.startImg2 = null

    this.listenerClick = this.goTo.bind(this)

    this.textRevealHandler = new TextRevealHandler()
    this.imageParallaxHandler = new ImageParallaxHandler()
    this.historyImageHandler = new HistoryImagesHandler()
    this.heroHandler = new HeroImageHandler()
  }

  getDOMElements() {
    if ( isMobile() ) {
      const images4 = document.querySelector(".sea_history_image4__container")
      if (images4) {
        images4.style.display = "none"
      }
    } else {
      const images4Mobile = document.querySelector(
        ".sea_history_image4_mobile__container"
      )
      if (images4Mobile) {
        images4Mobile.style.display = "none"
      }
    }

    this.wrapper = document.querySelector(".wrapp--sea-history")
    this.img2 = document.querySelector(".sea_history_image2")
    this.hero = document.getElementById("history-hero-img")
  }

  goTo(e) {
    // TO DO e.srcElement.getAttribute('data-index')
    barba.go("index.html")
  }

  updateAnimation() {
    this.textRevealHandler.animate()
    this.listenerClick = this.goTo.bind(this)

    this.textRevealHandler = new TextRevealHandler()
    this.imageParallaxHandler = new ImageParallaxHandler()
    this.historyImageHandler = new HistoryImagesHandler()
    this.heroHandler = new HeroImageHandler()
  }

  update() {
    this.getDOMElements()
    this.updateAnimation()
    this.handleNext()
  }

  handleNext() {
    const nextElement = document.querySelector("#nextSea")
    // Video Observer
    const optionsNext = {
      root: null,
      rootMargin: "0px",
      threshold: 0.25,
    }

    const observerNext = new IntersectionObserver((elements) => {
      elements.forEach((element) => {
        if (element.isIntersecting ? true : false) {
          isEN() ? barba.go(`${routes['domain']['en']}#history`) : barba.go(`${routes['domain']['fr']}#history`) ;
        }
      })
    }, optionsNext)
    observerNext.observe(nextElement)
  }

  animate() {
    ScrollHandler.call(this)

    this.wrapper.style.transform = `translate3d(0px, ${-this.positionY}px, 0px)`

    if (this.heroHandler) {
      this.heroHandler.animate(this.positionY)
    }

    if (this.historyImageHandler) {
      this.historyImageHandler.animate()
    }

    if (this.imageParallaxHandler) {
      this.imageParallaxHandler.animate(this.positionY)
    }

    // Go back to initial position on tiny scroll --> BETWEEN 2 VALUES [-1, -2] for example or [2,3]
  }

  destroy() {
    this.positionX = 0
    this.positionY = this.rounded = 0
    this.activeIndex = 0
    this.pause = false

    this.transition = false

    this.speed = 0

    this.minY = 0
    Scroller()

    this.startImg2 = null
  }
}

export default SeaHistory
