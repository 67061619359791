import Splitting from "splitting"
import gsap from "gsap"

class TextRevealHandler {
  constructor() {
    this.observedElements = []
  }

  animate() {
    const texts = [...document.querySelectorAll('.text_reveal')];

    // Get texts which need to be splitted by lines
    const textsToSplit = texts.filter(t => {
      return !t.hasAttribute("data-block")
    })

    // Get texts which will be animated by block
    const textsNoSplit = texts.filter(t => {
      return t.hasAttribute("data-block")
    })

    const resSplit = Splitting({ target: textsToSplit, by: "lines" })

    resSplit.forEach((element, index) => {
      this.observedElements[index] = { lines: element.lines, appeared: false }
      element.lines.forEach((line) => {
        line.forEach((word) => {
          gsap.set(word, { opacity: 0})
        })
      })
    })

    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.001,
    }

    textsToSplit.forEach((text, i) => {
      const observer = new IntersectionObserver((entries) => {
        this.observerCallbackForSplitted(entries, observer, i)
      }, observerOptions)
      observer.observe(text)
    })

    textsNoSplit.forEach((text, i) => {
      const observer = new IntersectionObserver((entries) => {
        this.observerCallbackForBlocks(entries, observer, i)
      }, observerOptions)
      observer.observe(text)
    })
  }

  observerCallbackForSplitted(resElements, observer, i){
    const element = this.observedElements[i]
      element.isVisible = resElements[0].isIntersecting
      if(element.isVisible && !element.appeared){
        element.lines.forEach((line, indexLine) => {
          line.forEach((word) => {
            gsap.fromTo(word, 
              { opacity: 0, y: 100}, 
              {
                duration: 0.5,
                opacity: 1,
                y: 0,
                delay: (indexLine + 1) * 0.3,
              }
            )

            // gsap.fromTo(document.querySelectorAll(".text_reveal")[i], {y:200, opacity: 0}, {y: 0, opacity: 1, duration: 0.5})
          })
        })
        element.appeared = true
      } else {
        element.appeared = false
      }
  }

  observerCallbackForBlocks(entries){
    entries.forEach(function(entry) {
      if (entry.isIntersecting && !entry.appeared) {
        gsap.fromTo(entry.target, 
          { opacity: 0, y: 100}, 
          {
            duration: 0.5,
            opacity: 1,
            y: 0,
          }
        )
        entry.appeared = true
      } else {
        entry.appeared = false
      }
    });
  }
}

export default TextRevealHandler
