import Menu from "../views/Menu"
import Signals from "./Signals"
import lottie from 'lottie-web'

class MenuBar {
  constructor() {
    this.scaleNavbar = 1
    this.min = 0
    this.max = 1
    this.alwaysOpen = false
    this.update()
    const soundButtonAnim = document.querySelector(".home_navbar_wave > svg")
    
    if (!soundButtonAnim) {
      this.animationSound = lottie.loadAnimation({
        container: document.querySelector(".home_navbar_wave"),
        renderer: 'svg',
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid meet',
          progressiveLoad: false,
        },
        loop: true,
        autoplay: window.States.soundEnabled,
        path: '/lotties/sound.json'
      })
    }
  }

  update() {
    this.navbar = document.querySelector(".common_navbar")
    this.menu = new Menu()
    this.menuLink = document.querySelector(".common_navbar_link")
    this.menuHamburger = document.querySelector(".common_navbar_hamburger")
    this.soundButton = document.querySelector(".home_navbar_wave")

    if (this.menuLink) this.menuLink.onclick = () => this.menu.onOpen()
    if (this.menuHamburger)
      this.menuHamburger.onclick = () => this.menu.onOpen()

    Signals.onScroll.add(() => this.animate())
    
    if (this.soundButton) this.soundButton.onclick = () => {
      Signals.toggleSound.dispatch()
    }
    Signals.soundChange.add((value) => this.handleSoundChange(value))
  }
  
  handleSoundChange(enabled) {
    if (enabled) {
      this.animationSound.play()
    } else {
      this.animationSound.stop()
    }
  }

  animate(manualSpeed) {
    const speed = manualSpeed || window.speedY

    if (speed < 0 && !this.alwaysOpen) {
      this.navbar.classList.remove("common_open")
    } else {
      this.navbar.classList.add("common_open")
    }
  }
  
}

export default MenuBar
