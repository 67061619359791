import Signals from './Signals'

class States {
    constructor() {
        this.soundEnabled = true;
        this.assetsLoaded = false;
        
        Signals.onAssetsLoaded.add(() => {
          this.assetsLoaded = true
        })
        
        Signals.toggleSound.add((value) => {
          const newVal = value === undefined ? !this.soundEnabled : value
          
          this.soundEnabled = newVal
          Signals.soundChange.dispatch(newVal)
        })
    }
}

window.States = new States()

export default window.States
