import Scroller from "../cores/Scroller";
import gsap from "gsap";
import { ScrollToPlugin, ScrollTrigger } from "gsap/all";
import raf from "raf";
import barba from "@barba/core";
import Player from "@vimeo/player";
import TextRevealHandler from "../cores/TextRevealHandler";
import HistoryImagesHandler from "../cores/HistoryImagesHandler";
import ImageParallaxHandler from "../cores/ImageParallaxHandler";

import ScrollHandler from "../cores/ScrollHandler";
import { isEN } from "../cores/Utils";
import { isDevice } from "../cores/Constants";
import App from "../app";
import NewsletterHandler from "../cores/NewsletterHandler";

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);

class Common {
  constructor() {
    this.positionY = 0;
    this.speed = 0;
    this.minY = 0;

    // Video institutionnal
    this.videoParams = {
      animating: false,
      startScrollRef: null,
      startOffsetY: 10,
      startOffsetX: 36,
      offsetY: 10, // equal to startOffsetY at the beginning
      offsetX: 36, // equal to startOffsetX at the beginning
      offsetYMultiplicator: 5, // Anim is triggered at 50% so startOffsetY under 50% have to be convert - Multiplicator is equal to 50/startOffsetY
      clip: [36, 10, 64, 10, 64, 100, 36, 100],
      isFullViewport: false,
      isFullScreen: false,
    };

    this.isPlaying = false;

    this.resetView = true;

    this.listenerPlayVideo = this.playVideo.bind(this);
    this.listenerCloseVideo = this.closeVideo.bind(this);

    this.historyImageHandler = new HistoryImagesHandler();
    this.textRevealHandler = new TextRevealHandler();
    this.imageParallaxHandler = new ImageParallaxHandler();
    this.newsletterHandler = new NewsletterHandler();

    Scroller();
  }

  getDOMElements() {
    this.common = document.querySelector(".common_container");
    this.next = document.querySelector("#next");
    this.images = document.querySelector(".common_history_images");
    this.isPlaying = false;

    this.video = document.querySelector("#institutional-video");
    this.videoTheOne = document.querySelector("#video-theone");
    this.videoPlayer = document.querySelector("#video-content");
    this.videoContentContainer = document.querySelector(
      "#video-content-container",
    );
    this.player = new Player(document.querySelector("#video-player"));
    this.videoOuter = document.querySelector("#video-outer");
    this.videoAnchor = document.querySelector("#video-anchor");
    this.videoClose = document.querySelector("#institutional-video-close");

    if (isDevice) {
      this.video.style.display = "none";
      this.videoOuter.style.display = "none";
    } else {
      const photobloc = document.querySelector(".common_photobloc");
      photobloc.style.display = "none";
    }
  }

  handleNewsletter() {
    this.newsletterHandler = new NewsletterHandler();
  }

  handleNext() {
    const optionsNext = {
      root: null,
      rootMargin: "0px",
      threshold: 0.25,
    };

    const observerNext = new IntersectionObserver((elements) => {
      elements.forEach((element) => {
        if (element.isIntersecting ? true : false) {
          isEN() ? barba.go("/en/") : barba.go("/");
        }
      });
    }, optionsNext);
    observerNext.observe(this.next);
  }

  setEvents() {
    ScrollTrigger.defaults({
      scroller: this.common,
    });

    ScrollTrigger.scrollerProxy(this.common, {
      scrollTop() {
        return Math.abs(this.scroller.positionY);
      },

      getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        };
      },
      pinType: "transform",
    });

    // const offsetY = ( window.innerWidth / window.innerHeight ) * 36

    this.timeline = gsap.timeline({
      onStart: () => {
        gsap.set("#video-outer", { opacity: 1 });
      },
      onComplete: () => {
        if (this.isPlaying === true) {
          gsap.to(this.videoTheOne, { opacity: 0, duration: 1 });
          this.player.pause();
          this.isPlaying = false;
        }
      },
      onReverseComplete: () => {
        gsap.set("#video-outer", { opacity: 0 });

        if (this.isPlaying === true) {
          gsap.to(this.videoTheOne, { opacity: 0, duration: 1 });
          this.player.pause();
          this.isPlaying = false;
        }
      },
      scrollTrigger: {
        trigger: "#institutional-video",
        scroller: ".common_container",
        scrub: true,
        anticipatePin: 1,
        pin: true,
        pinSpacing: true,
        start: "top top",
        // end: `+=${offsetY}%`,
        end: "+=175%",
        // markers: true,
        onLeave: () => {
          // gsap.set('#video-outer', { opacity: 0 })
        },
        onEnterBack: ({ progress }) => {
          // gsap.set('#video-outer', { opacity: 1 })
          // if( progress < 100 ){
          //   gsap.set('#video-content', { opacity: 0 })
          // }
        },
      },
    });

    gsap.set("#video-placeholder", {
      clipPath:
        "polygon(" +
        36 +
        "%" +
        0 +
        "%," +
        64 +
        "%" +
        0 +
        "%," +
        64 +
        "%" +
        100 +
        "%," +
        36 +
        "%" +
        100 +
        "%)",
    });
    // polygon(36% 0%, 64% 0%, 64% 79.5%, 36% 79.5%)
    gsap.set("#aside-left", { left: "12%" });
    gsap.set("#aside-right", { right: "12%" });
    gsap.set("#video-outer", { opacity: 0 });
    gsap.set("#video-content", { opacity: 0 });

    this.timeline
      .to(
        "#video-placeholder",
        {
          clipPath:
            "polygon(" +
            0 +
            "%" +
            0 +
            "%," +
            100 +
            "%" +
            0 +
            "%," +
            100 +
            "%" +
            100 +
            "%," +
            0 +
            "%" +
            100 +
            "%)",
          ease: "none",
          onUpdate: function () {
            if (this.progress() * 100 < 50) {
              gsap.set("#video-content", { opacity: 0 });
            }
          },
          onComplete: () => {
            gsap.set("#video-content", { opacity: 1 });
          },
        },
        -1.5,
      )
      .to("#aside-left", { left: `${-25}%` }, 0)
      .to("#aside-right", { right: `${-25}%` }, 0);

    this.updateLib();

    // Video Play
    this.videoContentContainer.addEventListener(
      "click",
      this.listenerPlayVideo,
    );

    // Video Close
    this.videoClose.addEventListener("click", this.listenerCloseVideo);

    // ScrollTop
    document.getElementById("scrollTop").addEventListener("click", () => {
      this.positionY = 0;
      this.common.style.transform = `translate3d(0px, 0px, 0px)`;
    });
  }

  scrollTo(hash, fading = false) {
    if (fading) {
      gsap.to(".common_container", { opacity: 0 });
    } else {
      gsap.set(".common_container", { opacity: 0 });
    }

    setTimeout(() => {
      this.positionY = 0;
      gsap.set(".common_container", { y: 0 });

      const section = document
        .querySelector(`#${hash}`)
        .getBoundingClientRect();
      const sectionOffsetY = section.top;

      gsap.to(".common_container", {
        duration: 0,
        opacity: 0,
        y: `-${sectionOffsetY}px`,
        onComplete: () => {
          this.positionY = sectionOffsetY;
          App.resumed();
          gsap.to(".common_container", { opacity: 1 });
        },
      });
    }, 1000);
  }

  playVideo() {
    if (this.isPlaying) {
      gsap.to(this.videoTheOne, { opacity: 0, duration: 1 });
      this.player.pause();
      this.isPlaying = false;
    } else {
      gsap.to(this.videoTheOne, { opacity: 1, duration: 1 });
      this.player.play();
      this.isPlaying = true;
    }
  }

  stopVideo() {
    gsap.to(this.videoTheOne, { opacity: 0, duration: 0.4 });
    this.player.pause();
    this.isPlaying = false;
  }

  closeVideo() {
    this.stopVideo();
    setTimeout(() => {
      this.scrollTo("terroir", true);
    }, 500);
  }

  updateAnimation() {
    // Texts and images animations
    this.textRevealHandler.animate();
    this.historyImageHandler.animate();

    if (this.imageParallaxHandler) {
      this.imageParallaxHandler.animate(this.positionY);
    }
  }

  update() {
    this.getDOMElements();
    this.setEvents();
    this.handleNext();
    this.updateAnimation();
    this.handleNewsletter();
    ScrollTrigger.refresh();
  }

  updateLib() {
    this.common.positionY = this.positionY;
    ScrollTrigger.update();

    raf(this.updateLib.bind(this));
  }

  scrollUp() {
    return Math.abs(Math.round(this.positionY)) - this.oldPositionY < 0
      ? true
      : false;
  }

  animate() {
    ScrollHandler.call(this);

    this.common.style.transform = `translate3d(0px, ${-this.positionY}px, 0px)`;

    // Texts and images animations

    if (this.imageParallaxHandler) {
      this.imageParallaxHandler.animate(this.positionY);
    }

    // Memorize old position of the scroll
    this.oldPositionY = Math.abs(Math.round(this.positionY));
  }

  destroy() {
    this.speed = 0;
    this.minY = 0;

    this.timeline.kill();

    this.videoParams = {
      animating: false,
      startScrollRef: null,
      startOffsetY: 10,
      startOffsetX: 36,
      offsetY: 10, // equal to startOffsetY at the beginning
      offsetX: 36, // equal to startOffsetX at the beginning
      offsetYMultiplicator: 5, // Anim is triggered at 50% so startOffsetY under 50% have to be convert - Multiplicator is equal to 50/startOffsetY
      clip: [36, 10, 64, 10, 64, 100, 36, 100],
      isFullViewport: false,
      isFullScreen: false,
    };
    this.isPlaying = false;

    if (
      ![
        "/rose-wine",
        "/white-wine",
        "/sea-history",
        "/earth-history",
        "/wine-history",
      ].includes(document.location.pathname.replace(/\.[^.]*$/, ""))
    ) {
      this.positionY = 0;
      this.common.style.transform = `translate3d(0px, 0px, 0px)`;
    }
  }
}

export default Common;
