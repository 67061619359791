export default function() {
  let speed = window.speedY

  if (Math.sign(speed) != Math.sign(this.speed)) {
    this.speed = 0
  }

  this.speed += speed * 100 * 0.05 // easing value
  this.positionY -= Math.round(this.speed * 1000) / 1000

  if (Math.abs(this.speed) < 0.0001) this.speed = 0 // prevents infinite calculations
  else this.speed *= 0.95 // anchor value

  if (this.positionY < this.minY) {
    this.positionY = this.minY
    speed = 0
  }
}
