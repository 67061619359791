import Scroller from "../cores/Scroller"
import barba from "@barba/core"
import gsap from "gsap"
import TextRevealHandler from "../cores/TextRevealHandler"
import HeroImageHandler from "../cores/HeroImageHandler"
import ImageParallaxHandler from "../cores/ImageParallaxHandler"
import HistoryImagesHandler from "../cores/HistoryImagesHandler"
import ScrollHandler from '../cores/ScrollHandler'
import { isEN } from "../cores/Utils"

const routes = require('../routes.json')

class EarthHistory {
  constructor() {
    this.positionX = 0
    this.positionY = this.rounded = 0
    this.activeIndex = 0
    this.pause = false

    this.transition = false

    this.speed = 0

    this.minY = 0
    Scroller()

    this.animNext = false
    this.observeNext = true

    this.listenerClick = this.goTo.bind(this)


    this.imageParallaxHandler = new ImageParallaxHandler()
    this.textRevealHandler = new TextRevealHandler()
    this.heroHandler = new HeroImageHandler()
    this.historyImageHandler = new HistoryImagesHandler()
  }

  getDOMElements() {
    this.wrapper = document.querySelector(".wrapp--earth-history")
  }

  goTo(e) {
    // TO DO e.srcElement.getAttribute('data-index')
    barba.go("index.html")
  }

  updateAnimation(){
    this.textRevealHandler.animate()
  }

  update() {
    this.getDOMElements()
    this.handleNext()
    this.updateAnimation()
  }

  handleNext() {
    const nextElement = document.querySelector("#nextEarth")
    // Video Observer
    const optionsNext = {
      root: null,
      rootMargin: "0px",
      threshold: 0.25,
    }

    const observerNext = new IntersectionObserver((elements) => {
      elements.forEach((element) => {
        if (element.isIntersecting ? true : false) {
          isEN() ? barba.go(`${routes['domain']['en']}#history`) : barba.go(`${routes['domain']['fr']}#history`) ;
        }
      })
    }, optionsNext)
    observerNext.observe(nextElement)
  }

  animate() {
    ScrollHandler.call(this)

    if (this.heroHandler) {
      this.heroHandler.animate(this.positionY)
    }

    if (this.historyImageHandler) {
      this.historyImageHandler.animate()
    }

    if (this.imageParallaxHandler) {
      this.imageParallaxHandler.animate(this.speed)
    }

    this.wrapper.style.transform = `translate3d(0px, ${-this.positionY}px, 0px)`

    // Go back to initial position on tiny scroll --> BETWEEN 2 VALUES [-1, -2] for example or [2,3]
  }

  destroy() {}
}

export default EarthHistory
