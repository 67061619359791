export default class FirefoxAlert {
  constructor() {
    this.container = document.getElementById('firefox-alert')
    this.modal = document.getElementById('firefox-modal')
    this.confirmed = document.getElementById('firefox-confirmed')

    this.confirmed.addEventListener('click', () => {
      Signals.firefoxConfirmed.dispatch()
      this.cancelled()
    })
  }

  launch() {
    this.modal.style.display = 'block'
  }

  cancelled() {
    this.container.style.display = 'none'
  }
}
