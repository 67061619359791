import barba from "@barba/core";
import { isEN } from "./Utils";
import axios from "axios";
import ConfigService from "./ConfigService";

export default class NewsletterHandler {
  constructor() {
    this.input = document.querySelector(".newsletter__input");
    this.button = document.querySelector(".newsletter__submit");
    this.form = document.querySelector(".newsletter__form");
    this.env = {};

    ConfigService.loadConfig()
      .then((config) => {
        this.env = config;
        this.loadRecaptcha();
      })
      .catch((error) => {
        console.error("Erreur:", error);
      });

    if (this.input) {
      this.input.addEventListener("focus", () => {
        const mentions = document.querySelector(".newsletter__mentions");
        if (mentions) {
          mentions.classList.remove("hidden");
        }
      });

      this.input.addEventListener("input", () => {
        this.validateEmail();
      });
    }

    if (this.form) {
      const hash = window.location.hash;
      if (hash) {
        const ancreElement = document.querySelector(hash);
        if (ancreElement) {
          ancreElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }

      this.form.addEventListener("submit", (e) => {
        e.preventDefault();
        if (this.validateEmail()) {
          this.button.disabled = true;
          const self = this;
          const redirectUrl = encodeURIComponent(window.location.href);

          ConfigService.loadConfig()
            .then((config) => {
              this.env = config;
              grecaptcha.ready(() => {
                grecaptcha
                  .execute(self.env.recaptchaPublicKey, {
                    action: "submit",
                  })
                  .then(function (token) {
                    axios
                      .post(self.env.apiBaseUrl + "/newsletter", {
                        email: self.input.value,
                        token,
                      })
                      .then(function (response) {
                        isEN()
                          ? barba.go(
                              `/en/confirmation?success=true&redirectUrl=${redirectUrl}`,
                            )
                          : barba.go(
                              `/confirmation?success=true&redirectUrl=${redirectUrl}`,
                            );
                      })
                      .catch(function (error) {
                        isEN()
                          ? barba.go(
                              `/en/confirmation?success=false&redirectUrl=${redirectUrl}`,
                            )
                          : barba.go(
                              `/confirmation?success=false&redirectUrl=${redirectUrl}`,
                            );
                      });
                  });
              });
            })
            .catch((error) => {
              console.error("Erreur:", error);
            });
        } else {
          this.button.disabled = false;
          this.displayError();
        }
      });
    }
  }

  async loadConfigurations() {
    const { data } = await axios.get("/env.json");
    return data;
  }

  validateEmail() {
    const emailValue = this.input.value;
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const isValid = emailPattern.test(emailValue);

    if (isValid) {
      this.removeError();
      this.email = emailValue;
    } else {
      this.displayError();
    }

    return isValid;
  }

  displayError() {
    this.form.classList.add("newsletter__form--error");
  }

  removeError() {
    this.form.classList.remove("newsletter__form--error");
  }

  loadRecaptcha(callback) {
    const script = document.createElement("script");
    const recaptchaKey = this.env.recaptchaPublicKey;
    script.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaKey}`;
    script.onload = callback;
    document.head.appendChild(script);
  }
}
