import ScrollerElements from '../cores/ScrollerElements'
import barba from '@barba/core'
import gsap from 'gsap'
import lerp from 'lerp'
import { getAspectRatio, isMobile, isFirefox, isEN, getPathname } from '../cores/Utils.js'
import Sounds from '../cores/Sounds'

const routes = require('../routes.json')

class Element {
  constructor() {
    this.reinitialize()

    this.transition = false
    this.isMobile = isMobile()

    // First panel animation to second panel
    this.firstSVG = {
      offsetX: isMobile() ? '-32px' : getAspectRatio() > 1.7 ? '-20%' : '-40%',
      offsetY: isMobile() ? '0' : '20%',
      maskWidth: isMobile() ? '16px' : getAspectRatio() > 1.7 ? '20%' : '40%',
      maskHeight: isMobile() ? '100%' : '70%'
    }

    // Last panel
    this.lastSVG = {
      offsetX: isMobile() ? '-32px' : '-40%',
      maskWidth: isMobile() ? '16px' : '40%'
    }

    // Nav
    this.listenerToggleNav = this.toggleNav.bind(this)
    
    this.soundStart()
  }
  
  soundStart() {
    const currentPathname = getPathname()

    if(currentPathname.length > 0){
      if(currentPathname === 'eau' || currentPathname === 'water') Sounds.play(0)
      if(currentPathname === 'terre' || currentPathname === 'earth') Sounds.play(1)
      if(currentPathname === 'vents' || currentPathname === 'winds') Sounds.play(2)
      if(currentPathname === 'lumiere' || currentPathname === 'light') Sounds.play(3)
    }
  }

  reinitialize() {
    this.positionX = 0
    this.positionY = 0
    this.rounded = 0
    this.activeIndex = 0
    this.panelsAnimatedIndexes = []
    this.speed = 0
    this.speedEasing = isMobile() || isFirefox() ? 0.05 : 0.01
    this.transition = false
    this.navToggled = false
  }

  initAnimation() {
    this.tlNav = gsap.timeline({
      paused: true,
      onComplete: () => {
        this.navToggled = true
      },
      onReverseComplete: () => {
        this.navToggled = false

        gsap.to('#nav-element-pointer', { autoAlpha: 1 })
        gsap.to('#nav-element-content', { autoAlpha: 0 })
        gsap.to('.common_navbar_hamburger', { autoAlpha: 1 })
        this.isMobile && gsap.to('.home_navbar_right', { autoAlpha: 1 })
      }
    })

    this.tlNav.to('.common_navbar_hamburger', { autoAlpha: 0 }, '-=0.5')
    this.isMobile && this.tlNav.to('.home_navbar_right', { autoAlpha: 0 }, '-=0.5')
    this.tlNav.to('#nav-element-pointer', { autoAlpha: 0 }, '-=0.5')
    this.tlNav.to('#nav-element-content', { autoAlpha: 1 }, '-=0.5')
    this.tlNav.to('.nav-element__item', {
      autoAlpha: 1,
      y: 0,
      stagger: 0.1
    })
  }

  getDOMElements() {
    this.wrapper = document.querySelector('.element__wrapper')

    // Nav
    this.navPointer = document.querySelector('#nav-element-pointer')
    this.navContent = document.querySelector('#nav-element-content')
    this.navItems = document.querySelector('.nav-element__item')

    // First panel translation
    this.mask = document.querySelector('#rect')
    this.svg = document.querySelector('.panel__containerMask')

    // Panels
    this.panels = document.querySelectorAll('.panel')

    // /!\ Only the last one is animated but design was not fixed for development
    this.checkPanelsAnimated()
  }

  checkPanelsAnimated() {
    this.panels.forEach((p, i) => {
      if (p.classList.contains('panel--fullwithtranslation')) {
        this.panelsAnimatedIndexes.push(i)
      }
    })

    if (this.panelsAnimatedIndexes.length > 0) {
      this.panelsTranslates = document.querySelectorAll('.panel__translate')
      this.panelsMasks = document.querySelectorAll('.panel__mask')
    }
  }

  setAppear() {
    gsap.set('#element-img', { x: this.firstSVG.offsetX })
    gsap.set(this.mask, { y: this.firstSVG.offsetY, width: this.firstSVG.maskWidth, height: this.firstSVG.maskHeight })
    gsap.set('.nav-element__item', { autoAlpha: 0, y: 100 })

    this.tlAppear = gsap.timeline({
      onComplete: () => {
        if (this.isMobile) {
          gsap.to('#nav-element-pointer', { autoAlpha: 1, duration: 0.2 })
          gsap.to('.common_navbar_hamburger', { autoAlpha: 1, duration: 0.2})
          gsap.to('.home_navbar_right', { autoAlpha: 1, duration: 0.2 })
        } else {
          this.toggleNav()
        }
        this.setEvents()
      }
    })
    this.tlAppear.to('#element-title', { y: 0, autoAlpha: 1, duration: 1 })
    this.tlAppear.to('#element-img', { y: 0, autoAlpha: 1, duration: 1 }, '-=.4')
    this.tlAppear.to('#element-desc', { y: 0, autoAlpha: 1, duration: 1 }, '-=.6')
    this.tlAppear.to('#element-nav', { autoAlpha: 1, duration: 0.2 })
  }

  setEvents() {
    this.navPointer.addEventListener('click', this.listenerToggleNav)
  }

  toggleNav() {
    // We toggle elements links
    if (!this.navToggled) {
      this.tlNav.play()
    } else {
      this.tlNav.reverse()
    }
  }

  next() {
    if (!this.transition) {
      this.transition = true
      isEN() ? barba.go(routes['domain']['en']) : barba.go(routes['domain']['fr']) ;
    }
  }

  update() {
    this.getDOMElements()
    this.setAppear()
    this.animateHeroSVG()
    this.instanciateLastSvg()
    this.initAnimation()
    this.scroller = new ScrollerElements(this.stickToClosest.bind(this))
    this.soundStart()
  }

  animateHeroSVG() {
    // Translation in
    if (this.activeIndex == 1) {
      gsap.to('#elementDirection', { alpha: 0 })
      gsap.to(this.svg, { x: 0, duration: 2 })
      gsap.to(this.mask, {
        y: 0,
        width: '100%',
        height: '100%',
        duration: 2
      })
    }

    // Translation reverse
    if (this.activeIndex == 0) {
      gsap.to(this.mask, { y: this.firstSVG.offsetY, width: this.firstSVG.maskWidth, height: this.firstSVG.maskHeight, duration: 0.4 })
      gsap.to(this.svg, { x: this.firstSVG.offsetX })
      gsap.to('#elementDirection', { alpha: 1 })
    }
  }

  instanciateLastSvg() {
    // Instanciate
    gsap.set(this.panelsTranslates[0], {
      x: this.lastSVG.offsetX
    })
    gsap.set(this.panelsMasks[0], { width: this.lastSVG.maskWidth })
  }

  animateLastSVG() {
    // /!\ All code below could be refactored as soon as we know only the last panel is animated
    if (
      this.panelsAnimatedIndexes.length > 0 &&
      (this.panelsAnimatedIndexes.includes(this.activeIndex) || this.panelsAnimatedIndexes.includes(this.activeIndex + 1))
    ) {
      // Translation in
      if (this.panelsAnimatedIndexes.includes(this.activeIndex)) {
        let index = this.panelsAnimatedIndexes.indexOf(this.activeIndex)
        if (index != -1) {
          gsap.to(this.panelsTranslates[index], { x: 0, duration: 2 })
          gsap.to(this.panelsMasks[index], {
            y: 0,
            width: '100%',
            duration: 2
          })
        }
      }

      // Translation reverse
      if (this.panelsAnimatedIndexes.includes(this.previousIndex) && this.previousIndex > this.activeIndex) {
        let index = this.panelsAnimatedIndexes.indexOf(this.previousIndex)
        if (index != -1) {
          gsap.to(this.panelsMasks[index], { width: this.lastSVG.maskWidth })
          gsap.to(this.panelsTranslates[index], {
            x: this.lastSVG.offsetX
          })
        }
      }
    }
  }

  stickToClosest() {
    const itemIndex = Math.round(this.scroller.scroll.target)
    this.scroller.scroll.target = itemIndex
    this.previousIndex = this.activeIndex
    this.activeIndex = itemIndex

    this.animateHeroSVG()
    this.animateLastSVG()

    if (this.activeIndex !== this.previousIndex && this.navToggled) {
      this.toggleNav()
    }
    if (this.activeIndex !== this.previousIndex && this.activeIndex === 0 && !this.navToggled && !this.isMobile) {
      this.toggleNav()
    }
  }

  animate() {
    const { scroll } = this.scroller

    scroll.current = lerp(scroll.current, scroll.target, scroll.ease)

    this.positionX = -1 * scroll.current
    const maxPosition = -1 * (this.panels.length - 0.99) // 50% more than last panel
    if (this.positionX < maxPosition) {
      this.next()
    }
    if (this.positionX > 0) {
      // prevent scrolling left of origin
      this.positionX = 0
      scroll.target = 0
    }

    // Global wrapper anim
    this.wrapper.style.transform = `translate3d(${this.positionX * 100}vw, 0, 0)`
  }

  destroy() {
    this.wrapper.style.transform = `translate3d(0vw, 0, 0)`
    this.reinitialize()
    this.scroller.removeEventListeners()
  }
}

export default Element
